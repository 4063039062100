// import {
//     required, sameAs
// } from 'vuelidate/lib/validators';
import passwordRules from '../password/rules.js'

export const validationRules = {
    password: { ...passwordRules }
    // repeatPassword: {
    //     required,
    //     sameAs: sameAs('password')
    // }
}
