<template>
    <div class="">
        <RadiusLoader />
    </div>
</template>

<script>
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
// types
import {
    ACTION_UPDATE_CLIENT_DATA,
    LOGIN_RESET_MODULES
} from '@/store/modules/client/action-types.js'

import {
    ACTION_GET_CART_FROM_BACKEND
} from '@/store/modules/checkout/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    name: 'AuthIntermediate',
    components: {
        RadiusLoader
    },
    metaInfo: {
        title: 'Loading'
    },
    computed: {
        ...mapGetters('client', [
            'getterShowFakeReviews'
        ])
    },
    async created() {
        try {
            this[LOGIN_RESET_MODULES]()
            await Promise.all([this[ACTION_UPDATE_CLIENT_DATA](), this[ACTION_GET_CART_FROM_BACKEND]()])

            // send to fake reviews page

            if (this.getterShowFakeReviews) {
                this.$router.push({ name: 'fake-reviews' })
                return
            }

            // send back to page
            const login_last_location = localStorage.getItem('login_last_location');
            if (login_last_location) {
                localStorage.removeItem('login_last_location')
                window.location.href = login_last_location
                return
            }
            // send to base route /
            this.$router.push({ name: 'base' })
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error');
            setTimeout(() => {
                this.$router.push({ name: 'login' })
            }, 3000);
        }
    },
    methods: {
        ...mapCheckoutActions([
            ACTION_GET_CART_FROM_BACKEND
        ]),
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA,
            LOGIN_RESET_MODULES
        ])
    }
}
</script>
