<template>
    <div class="page-section-mask">
        <section class="faq profile grid-container search-category wrapper reset-pass">
            <div class="grid__col12">
                <form
                    class="reset-form"
                    @submit.prevent="validate"
                >
                    <div class="profile-info">
                        <div class="profile-info__form profile-info__form-reset-pass">
                            <div class="authentication">
                                <h1 class="form-block__title reset-title">
                                    Reset password
                                </h1>
                            </div>
                            <div class="profile-info__col50 profile-info__column">
                                <div class="password_field">
                                    <form-input
                                        v-model="$v.password.$model"
                                        name="password"
                                        legend="Password"
                                        prepend-icon="LockIcon"
                                        :field.sync="password"
                                        autocomplete="new-password"
                                        :maxlength="20"
                                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                        :type="showPassword ? 'text' : 'password'"
                                        :errors="validationMessage($v.password)"
                                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                        @clicked="showPassword = !showPassword"
                                        @input.native="$v.password.$touch()"
                                        @blur="$v.password.$touch(); passwordInFocus = false"
                                        @focus="passwordInFocus = true"
                                    />
                                    <div
                                        v-if="passwordInFocus && $v.password.$anyError"
                                        class="password-validation"
                                    >
                                        <password-validator-helper :password="password" />
                                    </div>
                                </div>
                            </div>
                            <!--                                    <div class="profile-info__col50 profile-info__column">-->
                            <!--                                        <div class="password_field">-->
                            <!--                                            <form-input-->
                            <!--                                                v-model="$v.repeatPassword.$model"-->
                            <!--                                                name="password"-->
                            <!--                                                legend="Confirm password"-->
                            <!--                                                prepend-icon="LockIcon"-->
                            <!--                                                :field.sync="repeatPassword"-->
                            <!--                                                autocomplete="new-password"-->
                            <!--                                                :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"-->
                            <!--                                                :type="showPassword ? 'text' : 'password'"-->
                            <!--                                                :errors="validationMessage($v.repeatPassword)"-->
                            <!--                                                :is-valid="$v.repeatPassword.$dirty && !$v.repeatPassword.$anyError"-->
                            <!--                                                @clicked="showPassword = !showPassword"-->
                            <!--                                                @input.native="$v.repeatPassword.$touch()"-->
                            <!--                                                @blur="$v.repeatPassword.$touch();"-->
                            <!--                                            />-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <footer class="profile-info__footer justify-center reset-footer">
                                <button
                                    :loading="loading"
                                    class="btn-base btn-main login_button reset_btn"
                                >
                                    <i class="far fa-save" />Submit
                                </button>
                            </footer>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
// EventBus
import { validationMessage } from 'vuelidate-messages'
import { validationMixin } from 'vuelidate'
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

// API
import Api from '@/helpers/api/index.js'

// MDI

// vuelidate
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/reset-password/rules'
import { formMessages } from '@/validation/reset-password/messages'
import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';
// elements
import FormInput from '@/components/common/form-elements/FormInput'

// vuex
import { ACTION_CLIENT_RESET_PASSWORD_SET_NEW } from '@/store/modules/client/action-types'

const { mapActions: mapClientActions } = createNamespacedHelpers('client')

export default {
    name: 'ResetPasswordForm',
    components: {
        FormInput,
        PasswordValidatorHelper
    },
    mixins: [validationMixin, formValidationMixin],
    validations: {
        ...validationRules
    },
    data() {
        return {
            showPassword: false,
            email: '',
            loading: false,
            password: '',
            // repeatPassword: '',
            passwordInFocus: false,
            expired: false
        }
    },
    mounted() {
        if (!this.$route.params.token) {
            eventBus.$emit(
                'showSnackBar',
                'The link has expired, you need to use the reset password form again.',
                'info'
            )
            this.$router.push({ name: 'forgot-password' })
        }
        // this.getData()
    },
    methods: {
        ...mapClientActions([ACTION_CLIENT_RESET_PASSWORD_SET_NEW]),
        validationMessage: validationMessage(formMessages),
        getData() {
            Api.post('/api/auth/forgot-password/validate-token', {
                token: this.$route.params.token
            })
                .then((response) => {
                    // set users email for which to update password
                    this.email = response.data
                })
                .catch(() => {
                    this.expired = true
                    eventBus.$emit(
                        'showSnackBar',
                        'The link has expired, you need to use the reset password form again.',
                        'info'
                    )
                    if (this.expired) {
                        setTimeout(() => {
                            this.$router.push({ name: 'forgot-password' })
                        }, 3500)
                    }
                })
        },
        async submit() {
            try {
                this.loading = true
                await this[ACTION_CLIENT_RESET_PASSWORD_SET_NEW]({
                    token: this.$route.params.token,
                    password: this.password
                })
                eventBus.$emit('showSnackBar', 'Your password has been changed', 'success')
                this.$router.push({ name: 'reset-success', params: { token: this.$route.params.token } })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
.profile-info {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: $main-gradient;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .profile-info__footer {
        box-shadow: none;
    }
    .reset-footer {
        padding: 0;
    }
    .reset-title {
        margin-bottom: 40px;
    }
}
.reset_btn {
    width: 180px;
}
.lock_icon {
    color: $second-color;
}
.reset-pass {
    margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
    .profile-info__form {
        .profile-info__col50 {
            &:nth-child(2) {
                margin-top: 40px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .reset-pass {
        padding: 50px 0 0;

        .profile-info__footer {
            justify-content: center;
        }
    }
    .profile-info__header {
        position: relative;
        .lock_icon {
            display: none;
        }
    }
}
.password_field{
    margin-bottom: 20px;
}

.profile-info__form-reset-pass {
    padding: 40px 30px 40px !important;
}
</style>
