<template>
    <section class="reset-password-form">
        <reset-password-form />
    </section>
</template>

<script>
// Elements
import ResetPasswordForm from '@/components/reset-password/ResetPasswordForm';

export default {
    components: {
        ResetPasswordForm
    },
    metaInfo: {
        title: 'Reset password'
    }
}
</script>

<style scoped lang="scss">
    .reset-password-form {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
